import { render, staticRenderFns } from "./SourceDisplay.vue?vue&type=template&id=4046b85a&scoped=true&"
import script from "./SourceDisplay.vue?vue&type=script&lang=js&"
export * from "./SourceDisplay.vue?vue&type=script&lang=js&"
import style0 from "./SourceDisplay.vue?vue&type=style&index=0&id=4046b85a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4046b85a",
  null
  
)

export default component.exports